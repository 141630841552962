import { translations } from '@binhatch/locale';
import { Button, InstanceProps, ModalHeader, ModalLayout } from '@binhatch/ui';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface CarouselProps {
  selected?: number;
  className?: string;
}

const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({ selected, className, children }) => {
  const [ref, emblaApi] = useEmblaCarousel({ align: 'center', loop: true, containScroll: false, startIndex: selected });

  const [hasPrevious, setHasPrevious] = React.useState(false);
  const [hasNext, setHasNext] = React.useState(false);

  const onSelect = React.useCallback((emblaApi: EmblaCarouselType) => {
    setHasPrevious(!!emblaApi.canScrollPrev());
    setHasNext(!!emblaApi.canScrollNext());
  }, []);

  React.useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className={classnames('mx-auto h-full', className)}>
      <div className="h-full overflow-hidden" {...{ ref }}>
        <div className="[touch-action:pan-y pinch-zoom] -ml-4 flex h-full">
          {React.Children.map(children, (item, index) => (
            <div key={index} className="[transform:translate3d(0, 0, 0)] min-w-0 max-w-full flex-[0_0_auto] pl-4">
              {item}
            </div>
          ))}
        </div>
      </div>

      <div className="absolute left-4 top-1/2 -translate-y-1/2">
        {hasPrevious && (
          <Button appearance="secondary" className="h-10 w-10 shadow" onClick={() => emblaApi?.scrollPrev()}>
            <span className="sr-only">
              <FormattedMessage id={translations.buttons.previous} />
            </span>

            <ArrowLeftIcon aria-hidden="true" className="h-5 w-5" />
          </Button>
        )}
      </div>

      <div className="absolute right-4 top-1/2 -translate-y-1/2">
        {hasNext && (
          <Button appearance="secondary" className="h-10 w-10 shadow" onClick={() => emblaApi?.scrollNext()}>
            <span className="sr-only">
              <FormattedMessage id={translations.buttons.next} />
            </span>

            <ArrowRightIcon aria-hidden="true" className="h-5 w-5" />
          </Button>
        )}
      </div>
    </div>
  );
};

interface Props extends InstanceProps<void, { images: string[]; selected?: string }> {}

export const GalleryModal = React.forwardRef<HTMLDivElement, Props>(({ data, initialFocus, className, onClose }, ref) => {
  const index = data.selected ? data.images.indexOf(data.selected) : -1;
  const selected = index < 0 ? undefined : index;

  return (
    <div {...{ ref }} className={classnames(className, 'h-full w-full')}>
      <ModalLayout className="h-full">
        <ModalHeader ref={initialFocus} {...{ onClose }}>
          &nbsp;
        </ModalHeader>

        <div className="relative flex flex-1 select-none flex-col">
          <div className="relative flex-1">
            <div className="absolute inset-0">
              <Carousel {...{ selected }}>
                {data.images.map((image) => (
                  <img key={image} src={image} className="h-full w-full object-contain" />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
});
