import classNames from 'classnames';
import { Period, Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';

import { CoinValue } from '../CoinValue';
import { PromotionItemPeriod } from '../promotions/PromotionItemPeriod';

interface Props {
  promotion: Promotion | UserPromotion;
  days: number;
  period?: Period;
  className?: string;
}

export const CustomDealItemContainer: React.FC<React.PropsWithChildren<{ className?: string }>> = (props) => (
  <div {...props} className={classNames('w-full overflow-hidden rounded-lg bg-white shadow', props.className)} />
);

export const CustomDealItem: React.FC<Props> = ({ promotion, period, days, className }) => (
  <React.Fragment>
    <div className={classNames('flex flex-1 flex-col gap-4', className)}>
      <PromotionItemPeriod {...{ promotion, period, days }} />

      <div className="flex flex-1 flex-col gap-1">
        <div className="font-semibold">{promotion.name}</div>

        <div className="line-clamp-2 text-sm">{promotion.description}</div>
      </div>
    </div>

    <CoinValue value={parseFloat(promotion.target.levels[0]?.value) ?? 0} />
  </React.Fragment>
);
