import { useOrderParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { OrderItem, OrderList, PageLoaderHeading, Pagination, SearchInput } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { orderApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';

export const OrderListPage: React.FC = () => {
  const intl = useIntl();
  const { context } = Auth.useContainer();
  const [query, updateQuery] = useOrderParams();

  const user = context?.user;
  const client = context?.client;

  const orders = useRemoteData(
    { key: `useOrders`, search: query.search, before: query.before, after: query.after, page: query.page },
    async ({ search, before, after, page: nextToken }) => {
      return await orderApi.listOrdersUser(nextToken, search, undefined, undefined, after?.toISOString(), before?.toISOString()).then((r) => r.data);
    }
  );

  const items = React.useMemo(() => {
    return (
      orders.data?.orders.map<OrderItem>((order) => ({
        order,
        user,
        client,
        url: urls.orders.getOne({ orderId: order.id ?? '' })
      })) ?? []
    );
  }, [orders, user, client]);

  return (
    <main className="space-y-6">
      <div className="flex flex-row flex-wrap items-center gap-4">
        <PageLoaderHeading className="flex-1" loading={orders.isLoading || orders.isValidating}>
          <FormattedMessage id={translations.pages.orderList.title} />
        </PageLoaderHeading>

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.orderList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ page: undefined, search })}
          />
        </div>
      </div>

      <OrderList loading={orders.isLoading || orders.isValidating} orders={items} />

      <Pagination
        hasNext={!!orders.data?.nextToken}
        // hasPrevious={!!tales.data?.previousToken}
        onNext={() => updateQuery({ page: orders.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
      />
    </main>
  );
};
