import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  Banner,
  Button,
  ButtonRadio,
  CustomDealItem,
  CustomDealItemContainer,
  EmptyState,
  LoadingState,
  PageLoaderHeading,
  Pagination,
  PromotionClaimed,
  PromotionTargetProgress,
  SearchInput
} from '@binhatch/ui';
import {
  fromPromotion,
  getAllFromApi,
  getCurrentPeriod,
  getFallbackPeriod,
  getLastCustomDealClientProgress,
  getPeriodEndDistance,
  getPromotionDays,
  sortPeriods
} from '@binhatch/utility';
import { Progress, ProgressStateAggregation, PromotionSortByField, PromotionType, SortDirection } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { progressApi, promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { RealtimeToggle } from '@/components/RealtimeToggle';
import { Configuration } from '@/containers/useConfiguration';

const statuses = ['active', 'expired'] as const;
type Status = (typeof statuses)[number];

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const status: QueryParamConfig<Status> = {
  decode: (value) => {
    const v = decodeSingleQueryParam(value, undefined);

    if (v && statuses.includes(v as Status)) return v as Status;

    return 'active';
  },
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, status, search };

export const CustomDealListPage: React.FC = () => {
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });
  const [{ value: configuration }] = Configuration.useContainer();

  const claimable = useRemoteData({ key: `useClaimablePromotionCount`, userClaimGracePeriod: configuration.userClaimGracePeriod }, ({ userClaimGracePeriod }) =>
    promotionApi
      .listUserPromotions(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        PromotionSortByField.EndsAt,
        SortDirection.Asc,
        true,
        PromotionType.CustomDeal
      )
      .then((r) => r.data.promotions.map(fromPromotion))
      .then(async (promotions) => {
        const promotion = promotions[0];

        if (!promotion) return;

        const periods = sortPeriods(promotion.periods ?? []);

        const progresses: Progress[] = await getAllFromApi(
          (nextToken) => progressApi.listUserProgress([promotion.id], undefined, nextToken).then((r) => r.data),
          (r) => r.data.filter((p) => !p.claimedAt && parseFloat(p.value) >= parseFloat(promotion.target.levels[0].value))
        );

        const period = periods
          .map((period) => ({
            period,
            progress: progresses.filter((p) => p.periodID === period.id)
          }))
          .find(({ progress }) => progress.length)?.period;

        if (!period) return;

        return { days: getPeriodEndDistance(period) + (userClaimGracePeriod ?? 0) };
      })
  );

  const promotions = useRemoteData(
    { key: `useCustomDeals`, status: query.status, search: query.search, page: query.page },
    async ({ search, status, page: token }) => {
      const startingAfter = undefined;
      const endingBefore = status === 'expired' ? new Date().toISOString() : undefined;
      const startingBefore = status === 'active' ? new Date().toISOString() : undefined;
      const endingAfter = status === 'active' ? new Date().toISOString() : undefined;

      return await promotionApi
        .listUserPromotions(
          token,
          undefined,
          startingAfter,
          endingBefore,
          startingBefore,
          endingAfter,
          search,
          PromotionSortByField.EndsAt,
          SortDirection.Desc,
          undefined,
          PromotionType.CustomDeal
        )
        .then((r) => r.data)
        .then((r) => ({ ...r, promotions: r.promotions.map(fromPromotion) }));
    }
  );

  const progress = useRemoteData(
    { key: 'useProgressForPromotions', ids: promotions.data?.promotions.map((p) => p.id) ?? [], realtime: configuration.realtime },
    async ({ ids, realtime }) => {
      if (!ids.length) return;

      return await getAllFromApi(
        (nextToken) =>
          progressApi
            .listUserProgress(ids, undefined, nextToken, realtime ? ProgressStateAggregation.Total : ProgressStateAggregation.Confirmed)
            .then((r) => r.data),
        (r) => r.data
      );
    }
  );

  const items = React.useMemo(() => {
    return (
      promotions.data?.promotions.map((promotion) => {
        const period = getCurrentPeriod(promotion.periods ?? []) ?? getFallbackPeriod(promotion.periods ?? []);

        return {
          promotion,
          days: period ? getPromotionDays(period) : 0,
          period,
          groups:
            promotion.ruleGroups?.map((group) => ({
              group,
              progress: getLastCustomDealClientProgress(promotion, period, group.target, progress.data ?? [], (progress) => {
                if (!group.id || !progress.value_detailed?.[group.id]) return 0;
                return parseFloat(progress.value_detailed[group.id]);
              })
            })) ?? []
        };
      }) ?? []
    );
  }, [promotions.data, progress.data]);

  return (
    <main className="space-y-2 xl:space-y-6">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <PageLoaderHeading loading={promotions.isLoading || promotions.isValidating}>
            <FormattedMessage id={translations.pages.customDealList.title} />
          </PageLoaderHeading>
        </div>

        <RealtimeToggle />
      </div>

      {!!claimable.data && (
        <Banner className="flex-wrap">
          <div className="flex-1">
            <div className="font-semibold">
              <FormattedMessage id={translations.pages.customDealList.banner.title} />
            </div>

            <div>
              <FormattedMessage
                id={translations.pages.customDealList.banner.message}
                values={{
                  days: claimable.data.days,
                  strong: (children) => <strong className="font-semibold">{children}</strong>
                }}
              />
            </div>
          </div>

          <Button appearance="secondary" as={Link} className="h-10 w-full px-4 sm:w-auto" state={{ from: 1 }} to={urls.customDeals.claims}>
            <FormattedMessage id={translations.pages.customDealList.banner.cta} />
          </Button>
        </Banner>
      )}

      <div className="flex items-center justify-between gap-2 xl:gap-4">
        <ButtonRadio
          className="h-10"
          id="period"
          items={statuses.map((value) => ({
            value,
            name: intl.formatMessage({ id: translations.enum.promotionStatus[value] })
          }))}
          name="period"
          type="radio"
          value={query.status}
          onChange={(status: Status) => updateQuery({ page: undefined, status })}
        />

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.promotionList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ page: undefined, search })}
          />
        </div>
      </div>

      <LoadingState loading={promotions.isLoading || promotions.isValidating}>
        {!promotions.isLoading && !promotions.data?.promotions.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.customDealList.empty} />
          </EmptyState>
        )}

        <ul className="grid min-h-[7rem] w-full gap-4 rounded-lg xl:grid-cols-2 2xl:grid-cols-3">
          {items.map(({ promotion, days, period, groups }) => (
            <li className="min-w-0" key={promotion.id}>
              <CustomDealItemContainer>
                <Link className="flex flex-col gap-4 p-4" state={{ from: 1 }} to={urls.customDeals.getOne({ promotionId: promotion.id })}>
                  <CustomDealItem {...{ promotion, period, days }} />

                  {groups.map(({ group, progress }, index) => (
                    <PromotionTargetProgress
                      key={index}
                      name={group.rules
                        .map((rule) => `${rule.tagKey}: ${rule.value?.value ?? ''}`)
                        .filter(Boolean)
                        .join(', ')}
                      {...{ promotion, progress }}
                    />
                  ))}
                </Link>

                {groups.some((g) => g.progress.claimed) && (
                  <div className="-mt-4 flex justify-end p-4">
                    <PromotionClaimed />
                  </div>
                )}
              </CustomDealItemContainer>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!promotions.data?.nextToken}
        // hasPrevious={!!tales.data?.previousToken}
        onNext={() => updateQuery({ page: promotions.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
      />
    </main>
  );
};
