import { translations } from '@binhatch/locale';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface PaginationButtonProps {
  className?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

const PaginationButton: React.FC<React.PropsWithChildren<PaginationButtonProps>> = ({ active, ...props }) => (
  <button
    type="button"
    {...props}
    {...(active && { 'aria-current': 'page' })}
    className={classNames(
      'relative inline-flex h-10 flex-1 items-center justify-center border border-solid border-gray-200 text-sm font-semibold',
      {
        'bg-white text-gray-300': props.disabled,
        'bg-white hover:bg-[#F0FAFF] focus:z-20': !props.disabled && !active,
        'z-10 bg-[#2E4C86] text-white': !props.disabled && active
      },
      props.className
    )}
  />
);

interface PaginationProps {
  // hasPrevious: boolean;
  hasNext: boolean;
  className?: string;
  // onPrevious(): void;
  onNext(): void;
}

export const Pagination: React.FC<PaginationProps> = ({ hasNext, className, onNext }) => (
  <div className={classNames('flex w-auto items-center justify-end', className)}>
    <nav aria-label="Pagination" className="isolate inline-flex gap-2 rounded">
      {/* <PaginationButton className="gap-2 rounded px-4" disabled={!hasPrevious} onClick={onPrevious}>
        <ChevronLeftIcon aria-hidden="true" className="-ml-2 h-5 w-5" />
        <FormattedMessage id={translations.buttons.previous} />
      </PaginationButton> */}

      <PaginationButton className="gap-2 rounded px-4" disabled={!hasNext} onClick={onNext}>
        <FormattedMessage id={translations.buttons.next} />
        <ChevronRightIcon aria-hidden="true" className="-mr-2 h-5 w-5" />
      </PaginationButton>
    </nav>
  </div>
);
