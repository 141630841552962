import dayjs from 'dayjs';
import { Period, Progress, Promotion, Target, TargetLevel, UserPromotion } from 'flexinet-api';

const getTimestamp = (date: string) => new Date(date).getTime();

export const getPeriodEndDistance = (period: Period) => {
  return Math.ceil(dayjs(period.endAt).diff(new Date(), 'day', true));
};

export const getPromotionDays = (period: Period) => {
  return Math.max(getPeriodEndDistance(period), 0);
};

const isCurrentPeriod = (period: Period, timestamp: number) => {
  return getTimestamp(period.startAt) <= timestamp && timestamp <= getTimestamp(period.endAt);
};

export const getCurrentPeriod = (periods: Period[]) => {
  const now = Date.now();

  return periods.find((period) => isCurrentPeriod(period, now));
};

export const getFallbackPeriod = (periods: Period[]) => {
  periods = sortPeriods(periods);

  if (!periods.length) return;

  const now = Date.now();

  if (getTimestamp(periods[0].startAt) >= now) return periods[0];

  return periods[periods.length - 1];
};

export const sortPeriods = (periods: Period[]) => {
  return periods
    .slice()
    .map((period) => ({ period, startAt: getTimestamp(period.startAt) }))
    .sort((p1, p2) => p1.startAt - p2.startAt)
    .map(({ period }) => period);
};

export const getCurrency = (promotion?: Promotion | UserPromotion) => {
  // TODO: configure fallback currency during build
  return promotion?.target.currency ?? 'RON';
};

export interface PromotionClientProgress {
  value: number;
  percent: number;
}

export const getPromotionClientProgress = (period: Period | undefined, progresses: Progress[], level: TargetLevel): PromotionClientProgress => {
  const progressDuringPeriod = period ? progresses.filter((p) => p.periodID === period.id) : [];
  const totalValue = progressDuringPeriod.reduce((c, p) => c + parseFloat(p.value), 0);

  const levelValue = parseFloat(level.value);

  const value = Math.min(totalValue, levelValue);
  const percent = Math.max(Math.min(value / levelValue, 1), 0);

  return { value, percent };
};

export const getProgressForPeriod = (progress: Progress[], promotion: UserPromotion | Promotion, period?: Period) => {
  if (!period) return [];
  return progress.filter((p) => p.promotionId === promotion.id && p.periodID === period.id);
};

export const getCurrentTargetLevelIndex = (levels: TargetLevel[], value: number) => {
  const index = levels.findIndex((level) => parseFloat(level.value) > value);

  if (index < 0) return levels.length - 1;

  return Math.max(index - 1, 0);
};

export const getNextTargetLevelIndex = (levels: TargetLevel[], value: number) => {
  return levels.findIndex((level) => parseFloat(level.value) >= value);
};

export const getNormalizedNextTargetLevelIndex = (levels: TargetLevel[], value: number) => {
  const index = getNextTargetLevelIndex(levels, value);

  if (index < 0) return levels.length - 1;

  return index;
};

export const getTotalValueFromProgress = (progress: Progress[], getter: (progress: Progress) => number = (progress) => parseFloat(progress.value)) => {
  return progress.reduce((c, p) => c + getter(p), 0);
};

export interface LastPromotionClientProgress extends PromotionClientProgress {
  index: number;
  level: TargetLevel;
  claimed: boolean;
}

export const getLastPromotionClientProgress = (
  promotion: UserPromotion | Promotion,
  period: Period | undefined,
  progresses: Progress[],
  getValueFromProgress: (progress: Progress) => number = (progress) => parseFloat(progress.value)
): LastPromotionClientProgress => {
  const progressDuringPeriod = getProgressForPeriod(progresses, promotion, period);

  const value = getTotalValueFromProgress(progressDuringPeriod, getValueFromProgress);

  const index = getNormalizedNextTargetLevelIndex(promotion.target.levels, value);

  const level = promotion.target.levels[index];
  const percent = Math.max(value / parseFloat(level.value), 0);

  return { index, level, value, percent, claimed: !!progressDuringPeriod?.some((p) => p.claimedAt) };
};

export const getLastCustomDealClientProgress = (
  promotion: UserPromotion | Promotion,
  period: Period | undefined,
  target: Target | undefined,
  progresses: Progress[],
  getValueFromProgress: (progress: Progress) => number = (progress) => parseFloat(progress.value)
): LastPromotionClientProgress => {
  const progressDuringPeriod = getProgressForPeriod(progresses, promotion, period);

  const value = getTotalValueFromProgress(progressDuringPeriod, getValueFromProgress);

  const targetLevels = target?.levels ?? [];

  const index = getNormalizedNextTargetLevelIndex(targetLevels, value);

  const level = targetLevels[index];
  const percent = Math.max(value / parseFloat(level.value), 0);

  return { index, level, value, percent, claimed: !!progressDuringPeriod?.some((p) => p.claimedAt) };
};

export const fromDate = (date: string): string => {
  const parsed = new Date(date);

  return new Date(parsed.getTime() + parsed.getTimezoneOffset() * 60000).toISOString();
};

export const toDate = (date: string): string => {
  const parsed = new Date(date);

  return new Date(parsed.getTime() - parsed.getTimezoneOffset() * 60000).toISOString();
};

export const fromPeriod = (period: Period): Period => ({
  ...period,
  startAt: fromDate(period.startAt),
  endAt: fromDate(period.endAt)
});

export const toPeriod = (period: Period): Period => ({
  ...period,
  startAt: toDate(period.startAt),
  endAt: toDate(period.endAt)
});

export const fromPromotion = <T extends Pick<Promotion, 'startAt' | 'endAt' | 'periods'>>(promotion: T): T => ({
  ...promotion,
  startAt: fromDate(promotion.startAt),
  endAt: fromDate(promotion.endAt),
  periods: promotion.periods?.map(fromPeriod)
});

export const toPromotion = <T extends Pick<Promotion, 'startAt' | 'endAt' | 'periods'>>(promotion: T): T => ({
  ...promotion,
  startAt: toDate(promotion.startAt),
  endAt: toDate(promotion.endAt),
  periods: promotion.periods?.map(toPeriod)
});
